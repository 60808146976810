.page-container {
    @apply w-full
}

.page-header {
    @apply flex items-start justify-between  px-[75px] py-5 border-b-2
}

.page-header-client {
    @apply flex items-start justify-between !px-10 !py-4 !border-b
}

.page-title {    
    @apply text-2xl font-bold text-[#495057] m-0
}

.page-body {
    @apply py-3 px-[75px] pt-[25px]
}

.page-body-client {
    @apply !py-3 !px-[16px] sm:!px-[8px] md:!px-10 !pt-[25px] bg-white
}

.page-body .nav-tabs-custom {
    @apply border-b-2 border-[#B5B5C3]
}

.page-body .nav-tabs-custom .nav-item .nav-link.active{
    @apply bg-transparent
}

.page-body .tag {
    @apply px-2 py-1 text-xs font-normal rounded leading-5
}

.tag-primary {
    @apply bg-[#E9E3F9]
}

.tag-success{
    @apply bg-[#DBECEE]
}

.tag-info{
    @apply bg-[#F2F5FF] text-[#5B73E8]
}

.page-body .btn  {
    @apply !px-[15px] !py-[7px]
}

.page-body .btn-sm  {
    @apply !px-3 !py-[6px]
}

.page-body .btn-secondary {
    @apply bg-white hover:bg-gray-200 border text-gray-500 hover:text-gray-700 border-gray-300 hover:border-gray-400 rounded
}

.page-body .form-control{
    @apply rounded
}

.page-body .square-switch {
    margin-left: 7px;
}
.page-body .square-switch input[switch] + label, .square-switch input[switch] + label:after {
    border-radius: 4px;
 }

 /* Target a specific div's scrollbar */
.custom-scroll::-webkit-scrollbar {
  height: 3px; /* height of the scrollbar */
  width: 3px; /* Width of the scrollbar */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

.custom-scroll::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0; /* Color of the track on hover */
}

.custom-scroll::-webkit-scrollbar-track:active {
  background-color: #ddd; /* Color of the track on active */
}

/*  */
 /* Target a specific div's scrollbar */
.slim-scroll::-webkit-scrollbar {
  height: 3px; /* height of the scrollbar */
  width: 3px; /* Width of the scrollbar */
}

.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners */
}

.slim-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

.slim-scroll::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0; /* Color of the track on hover */
}

.slim-scroll::-webkit-scrollbar-track:active {
  background-color: #ddd; /* Color of the track on active */
}

.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle{
    @apply !bg-gray-200 !border-gray-400 !text-gray-700
}

.link {
    @apply hover:underline  !important
}

.react-pdf__Page__canvas{
    margin: auto !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 780px) {
  .page-body {
    @apply py-3 px-[30px] pt-[25px]
  }
  .page-header {
    @apply flex items-start justify-between  px-[30px] py-5 border-b-2
}
  }
:root {
  --blue: #3498db;
  --green: #1abc9c;
  --yellow: #e67e22;
  --purple: #e74c3c;
  --default-color: var(--blue);
  --box-width: 150px;
  --box-height: 100px;
  --border-width: 10px;
}

.box {
  width: var(--box-width);
  position: relative;
  border-radius: 4px;
}

.box::after {
  content: "";
  display: block;
  border-width: var(--border-width) var(--border-width) 0;
  border-style: solid;
  border-color: var(--default-color) transparent transparent;
  position: absolute;
  bottom: calc(-1 * var(--border-width));
  left: 50%;
  transform: translateX(-50%);
}

.box.arrow-left-top::after
{  
  left: calc(-1 * 4px);
  bottom: auto;
  border-width: 5px;
  margin: 0;
  top: 0;
}

.box.blue.arrow-left-top::after{
  border-right-color: #E2EDF9;  
  border-top-color: #E2EDF9;
}
.box.purple.arrow-left-top::after{
  border-right-color: #EDE9F5;
  border-top-color: #EDE9F5;
}

.box.green.arrow-left-top::after {
  border-right-color: #E8F3E8;
  border-top-color: #E8F3E8;
}

.box.yellow.arrow-left-top::after{
  border-right-color: #FAEEDA;
  border-top-color: #FAEEDA;
}

.page-content {
	position: relative;
	overflow: hidden;
  min-height: 100vh;
}

.blur-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: start;
  padding-top:5rem ;
	color: #333;
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
}

.coming-soon-text {
	color: #444; 
	font-size: 3rem;
	text-align: center;
	text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4), 
	             4px 4px 12px rgba(0, 0, 0, 0.2), 
	             0px 0px 10px rgba(255, 255, 255, 0.8); 
}




.Toastify__toast-body{
  @apply !font-ibmplex
}

.Toastify__toast-container{
  z-index: 10000 !important;
}  

.truncate-middle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%; 
    }


@media only screen and (max-width: 768px) {
  /* to hide pagination text for mobile screens  */
  .mobile_hide {
    display: none!important;
  }
  /* For pagination in mobile view  */
  .notification_mobile {
    max-height: 50vh!important;
  }

  .mobile_alignment {
    flex-direction: column;
  }
  .remove_width{
    width: 100%!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
  }
}

.vertical-collpsed  .text_hide {
  display: none!important;
  @media only screen and (max-width: 993px) {
    display: flex!important;
}
}

/* Forced work break */
.break-on-mobile {
  white-space: nowrap; 
}
@media (max-width: 430px) {
  .break-on-mobile {
      white-space: normal!important;
      word-break: break-word!important; 
  }
}



@media (max-width:1250px) {
  .mobile_alignmentt {
    flex-direction: column-reverse;
    gap: 0!important;
  }
  .full_width_mobile{
    width: 100% !important;
    max-width: 100%!important;
  }
  .form-fields-card{
    width: 100% !important;
    min-width: 100%!important;
  }
  .workflow-assign{
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
    justify-content: center;
  }
 
}
.title_hover:hover{
  text-decoration: underline!important;
}
/* for mobile view in client's table */
@media (max-width: 768px) {
  .alignment {
    display: flex !important;
    flex-direction: column !important;
    gap: 16px;
  }
  .upload_doc_alignment{
    display: flex !important;
    flex-direction: column !important;
    gap: 0px!important;
  }
  .alignment_holder{
    width: 100% !important;
    min-width: 100%!important;
  }
  /* for file manager module */
  .navbar_padding_full{
    padding: 0px!important;
  }
  .extra_padding{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .file_searchbar{
    display: flex!important;
    justify-content: space-between;
    align-items: center!important;
  }
  .file_padding_none{
    padding: 0px!important;
    width: 100%!important;
    display: flex;
    justify-content: center!important;
    align-items: center!important;
    margin: 0px!important;
  }
  .file_x_padding{
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
  }
  .menu-icon {
    margin-left: 0 !important; 
    display: flex;
    align-items: center; 
    justify-content: center; 
  }
  .file_hide{
    display: none!important;
  }
 .file_tab_height{
  min-height: 100%!important;
 }
  .alignment_full{
    width: 100%!important;
  }
  .alignment_style{
    overflow-x: auto!important;
  }
  .full_width_mobile{
    width: 100% !important;
    max-width: 100%!important;
  }
  .form-fields-card{
    width: 100% !important;
    min-width: 100%!important;
  }
  .my-form-fields-card{
    width: 100% !important;
    min-width: 100%!important;
  }
  .full_placeholder{
    min-width: 200px;
  }
  /* for document listing table  */
  .doc_listing_flex{
    flex-direction: column!important;
    align-items: start!important;
    justify-content: left!important;
  }
  .align_left{
    justify-content: flex-start!important;
  }
  .tabs_alignment {
    width: 100%!important;
    display: block; 
    text-align: center!important;
    align-self: end!important;
}
  .tabs_alignmentt {
  width: 100%!important;
  height: 100% !important;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  flex-direction: column; 
}
.steps_bottom{
  padding-bottom: 10px;
  padding-left: 2%!important;
  padding-right: 2%!important;
}
.tabs_spacing{
  padding-left: 30px!important;
  padding-right: 30px!important;
}
}

.buttons_align_center{
  align-items: center!important;
}
/* to hide anytext for smaller devices */
@media (max-width: 768px) {
  .hide{
    display: none!important;
  }

  .mobile_margin {
    margin: 0 !important;
  }


}
@media (max-width: 635px) {
  .doc_styling{
    display: flex!important;
    flex-direction: column!important;
    align-items: flex-start!important;
    gap: 10px;
  }
  .doc_full_width{
    width: 100%!important;
  }
  .special_mobile_alignment{
    flex-direction: column!important;
  }
  .My_hide{
    display: none!important;
  }
}
@media (max-width: 564px) {
  .full_widthhh{
    width: 100%!important;
  }
  .special_mobile_alignmentt{
    flex-direction: column!important;
  }
  .form-field-workflow{
    width: 100%!important;
    min-width: 100%!important;
  }
}
@media (min-width: 635px) {
  .My_hide2{
    display: none!important;
  }
  .special_mobile_alignment{
    gap:10px!important;
  }
}

@media (max-width:768px) {
  .common_searchfield{
    display: flex;
    flex-direction: column!important;
    gap: 15px;
  }
  .edit_client_space{
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 10px!important;
    max-height: 550px!important;
  }
  .edit_client_alignment{
    display: flex!important;
    flex-direction: column!important;
  }
  .otp-field-adjustment{
    width: 23px!important;
    height: 23px!important;
    margin-right: 2px;
  }
  .otp_full_width{
    width: 100%!important;
  }
}



/* Default for wider screens: full title */
.line-clamps-1 {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media (max-width: 640px) {
  /* For mobile screens: truncate after a certain number of words */
  .line-clamps-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
   /*Outstanding table */
 .width_remove{
  width: 100%!important;
 }
 .height_remove{
  height: 100%!important;
 }
 .maxwidth_remove{
  max-width: 100%!important;
 }
 .workflows-outstandings tbody tr{
  height: auto!important;
  border: 0px!important;
  border-bottom: 1px solid white!important;
 }
 .workflow_header_hide{
  display: none!important;
 }
 .workflow_header_show{
  display: block!important;
 }
 .workflow_columns{
  display: flex;
  flex-direction: column!important;
  align-items: flex-start!important;
 }
 .workflow-height{
  height: 35px!important;
 }
 .workflow-div-height{
  height: 90px!important;
  align-items: center;
 }
 .workflow-space-between{
  width: 100%!important;
  justify-content: space-between;
 }
 .margin-below{
  margin-bottom: 10px;
 }
 .identification_document{
  display: flex;
  flex-direction: column;
 }
}



/*for files */
.file-box {
  width: 100%; 
}


.file-title,
.file-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; 
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all; 
    white-space: normal; 
}

/* workflow template listing header */
@media (max-width: 768px){
  .display_none{
    display: none!important;
  }
  .smaller_alignment{
    width: 100%!important;
    display: flex;
    justify-content: space-between;
    margin-bottom: -21px!important;
  }
  .remove_padding{
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 100%!important;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem!important;
  }
  .alignment_style{
    overflow-x: auto!important;
  }
  .signature_div_height{
    height: 75vh!important;
  }
  .signature_height{
    height: 60vh!important;
  }
}


 @media (max-width: 1250px) {
  .alignment_reviews{
    display: flex !important;
    flex-direction: column !important;
    gap: 16px;
  }
  .Outstanding_holder{
    width: 100% !important;
    min-width: 100%!important;
  }
  .field_mapping{
    width: 100%!important;
    display: flex!important;
    flex-direction: column;
  }
  .space_full{
    width: 100%!important;
  }
  .height_auto_fix{
    height: auto!important;
  }
 }

 @media (max-width: 560px) {
  .direction_flex{
    flex-direction: column!important;
    gap: 16px;
  }
  .full_width{
    width: 100%!important;
  }
  .page-body .btn{
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
  .client_details_flex{
    flex-direction: column!important;
  }
  .file_searchbar_button{
    min-width: 50px!important;
    gap: 5px;
  }
 }


 @media (max-width: 420px) {
  .display_none{
    display: none!important;
  }
  .main_div{
    width: 100%!important;
    justify-content: space-between!important;
  }
  .full_length{
    width: 100%!important;
  }
  .forced_break{
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
  }
  .fields_system{
    display: flex!important;
    flex-direction: column!important;
    align-items: left!important;
  }
  .remove_topspacing{
    padding-top: 0px!important;
    width: 100%!important;
    justify-content: end;
    display: flex;
  }

 }



.file-manager-action-dropdown{

}
.file-manager-action-dropdown-item{
  @apply flex cursor-pointer items-end space-x-2.5 px-3 py-2  hover:bg-main/10 hover:text-main text-gray-600 !text-[17px] !font-medium
}
.file-manager-action-dropdown-item i{
  @apply !mt-[2px]
}


/* Workflow details */
@media (max-width: 1200px) {
  .workflow-details{
    display: flex;
    flex-direction: column;
    justify-content: flex-start!important;
  }
  .f-width{
    width: 100%!important;
  }
  .Tasks-column{
    min-width: 1000px;
    margin-bottom: 20px;
  }
  .task-overflow{
    height: 100%;
    width: 100%!important;
    overflow-x: scroll;
    overflow-y: hidden;
  }

}
@media (max-width: 440px) {
  .padding{
    padding: 5px!important;
    font-size: 12px!important;
  }
  .nav_smaller_alignment{
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
}


.width-fit{
  width: fit-content;
}
.team-details-styling{
  padding: 20px;
}
@media (max-width: 990px) {
  .width-fit{
    width: 100%!important;
  }
  .team-details-styling{
    padding: 5px!important;
  }
}

@media (max-width: 700px) {
  .view_doc_height{
    height: 60vh!important;
  }
}



@media (max-width: 1250px) {
  .client_details{
    display: flex;
    flex-direction: column;
  }
  .details_fill_width{
    width: 100%!important;
  }
}



@media (max-width: 385px) {
  .remove_space{
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  .extra_smaller_devices{
    flex-direction: column;
    align-items: flex-start!important;

  }
  .dot_margin_remove{
    margin: 0px!important;
  }
}


@media (max-width: 1340px) {
  .payments_details{
    display: flex!important;
    flex-direction:column!important;
    gap: 0px!important;
    width: 100%!important;
  }
  .payments_full{
    width: 100%!important;
    min-width: 100%!important;
  }
}



@media (max-width:1100px) {
  .Create_doc_alignment {
    width: 100%!important;
    height: 100% !important;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    text-align: center; 
    flex-direction: column; 
  }
  .Create_doc_steps{
    padding-bottom: 10px;
    padding-left: 2%!important;
    padding-right: 2%!important;
  }
}


.setting_width{
  width:100%!important;
}
.Setting_overflow{
  overflow-x: auto!important;
}
@media (max-width:1300px) {
  .Setting_overflow{
    overflow-x: auto!important;
  }
  .setting_width{
    width: max-content!important;
  }
}
.setting_w{
  min-width: 100px!important;
}




.image-container {
  position: relative;
  
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: -8px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease;
}

.overlay-button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overlay-button:hover {
  background-color: #ddd;
}

.image-container:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
}



/* Add some styles for the modal */
.modal-class {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000!important;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.my-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11000; /* Ensure this is above offcanvas */
}

.my-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 11001; /* Ensure this is above overlay */
}